import React, { useEffect } from 'react';
import { createTheme } from '@mui/material/styles';
import { ThemeProvider } from '@mui/material/styles';
import { Redirect, Route, Switch } from 'react-router-dom';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { IntlProvider } from 'react-intl';
import 'assets/vendors/style';
import {
  DARK_GREEN,
  GREEN,
  WHITE,
} from 'modules/cbre-flow-Common/constants/ThemeColors';
import { setInitUrl } from 'modules/cbre-flow-Common/actions';
import greenTheme from './themes/greenTheme';
import whiteTheme from './themes/whiteTheme';
import AppLocale from '../lngProvider';
import AppLayout from './AppLayout';
import asyncComponent from '../util/asyncComponent';
import { ConnectedRouter } from 'connected-react-router';
import { history } from '../store';
import AuthProvider from './Services/AuthProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider/LocalizationProvider';
import AddNewOptionsDialog from 'modules/cbre-flow-add-new-options/components/addNewOptionDialog';
import AddNewOptions from 'modules/cbre-flow-add-new-options';
import CommonAlert from 'components/CommonAlert';
import {AnalyticsProvider} from "./Services/AnalyticsProvider";
/*import SiteVerification from './SiteVerification';*/

const getColorTheme = (themeColorName) => {
  let applyAppTheme;
  switch (themeColorName) {
    case GREEN: {
      applyAppTheme = createTheme(greenTheme);
      break;
    }
    case DARK_GREEN: {
      applyAppTheme = createTheme(greenTheme);
      break;
    }
    case WHITE: {
      applyAppTheme = createTheme(whiteTheme);
      break;
    }
    default:
      createTheme(greenTheme);
  }
  return applyAppTheme;
};

const getCurrentAppLocale = (locale, location) => {
  const showLanguageSelection = true; //location.pathname.indexOf('cpp') !== -1 || location.pathname.indexOf('pda') !== -1
  const isFusionApp = location.pathname.indexOf('fusion') !== -1;
  let currentAppLocale;
  if (isFusionApp) {
    currentAppLocale = AppLocale['en_US'];
  } else {
    currentAppLocale =
      AppLocale[showLanguageSelection ? locale.locale : 'en_US'];
  }
  return currentAppLocale;
};

const validateFusionRedirection = ({ authUser, encodedHref, props }) => {
  if (
    process.env.NODE_ENV !== 'development' &&
    authUser === null &&
    props.history.location.pathname?.indexOf('fusion') === -1
  ) {
    /*if (
      window.location.href === window.location.origin ||
      window.location.href === `${window.location.origin}/`
    ) {
      window.location.assign(`/login?redirectUrl=${encodedHref}`);
    } else {
      window.location.assign(`/login?redirectUrl=${encodedHref}`);
    }*/
    window.location.assign(`/login?redirectUrl=${encodedHref}`);
    return false;
  } else if (
    process.env.NODE_ENV === 'development' &&
    authUser === null &&
    props.history.location.pathname?.indexOf('fusion') === -1
  ) {
    window.location.assign(
      `http://localhost:5000/login?redirectUrl=${encodedHref}`,
    );
    return false;
  } else {
    return true;
  }
};

const App = (props) => {
  const dispatch = useDispatch();
  const { themeColor, darkTheme, locale } = useSelector(
    ({ settings }) => settings,
    shallowEqual,
  );
  const { authUser, initURL, isSystemAdmin, appsList } = useSelector(
    ({ auth }) => auth,
    shallowEqual,
  );
  
  const isDarkTheme = darkTheme;
  const { location } = props;
  useEffect(() => {
    window.__MUI_USE_NEXT_TYPOGRAPHY_VARIANTS__ = true;
    if (initURL === '') {
      dispatch(setInitUrl(props.history.location.pathname));
    }
  }, [
    dispatch,
    initURL,
    props.history.location.pathname,
    props.location.search,
  ]);

  let applyTheme;
  if (isDarkTheme) {
    document.body.classList.add('dark-theme');
    applyTheme = createTheme(darkTheme);
  } else {
    applyTheme = getColorTheme(themeColor);
    document.body.classList.add(themeColor);
  }

  // Detect macOS using navigator.userAgent
  if (/Macintosh|MacIntel|MacPPC|Mac68K/.test(navigator.userAgent)) {
    document.body.classList.add('mac-os');
  }
  
  const encodedHref = encodeURIComponent(window.location.href);
  const isLoginNotRequired = validateFusionRedirection({
    authUser,
    encodedHref,
    props,
  });
  if (!isLoginNotRequired) {
    return null;
  }
  applyTheme.direction = 'ltr';
  const currentAppLocale = getCurrentAppLocale(locale, location);



  return (
      <AnalyticsProvider>
        <ThemeProvider theme={applyTheme}>
          <LocalizationProvider dateAdapter={AdapterMoment} >
            <IntlProvider
              locale={currentAppLocale.locale}
              messages={currentAppLocale.messages}
            >
              <div className="app-main">
                <ConnectedRouter history={history}>
                  <AuthProvider initURL={initURL}>
                    <Switch>
                      <Route
                        path="/maintenance"
                        component={asyncComponent(
                          () => import('app/Utils/maintenance'),
                        )}
                      />
                      <Route component={() => <AppLayout authUser={authUser} appsList={appsList} isSystemAdmin={isSystemAdmin} />} />
                    </Switch> 
                    {/*<SiteVerification />*/}
                  </AuthProvider>
                </ConnectedRouter>
                <div id="popper-portal"></div>
                <CommonAlert/>
              </div>
              <AddNewOptions/>
            </IntlProvider>
          </LocalizationProvider>
        </ThemeProvider>
      </AnalyticsProvider>
  );
};

export default App;
